<template>
  <div class="box">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div class="profile">
      <!--  标签页-->
      <el-tabs v-model="activeName" class="el-nav">
        <!--    基本信息开始   -->
        <el-tab-pane label="修改密码" name="first" v-loading="!isShow">
          <el-form :label-position="labelPosition" label-width="120px" :model="basicForm" ref="basicForm">
            <!-- <el-form-item label="原密码" prop="old_password" :rules="{ required: true, message: '请输入原密码', trigger: 'blur' }">
              <el-input class="pass-input" maxlength="20" type="password" v-model.trim="basicForm.old_password" placeholder="请输入密码8-20位，大小写字母、数字、符号至少三种"></el-input>
            </el-form-item> -->

            <el-form-item label="密码" prop="password" :rules="{ required: true, message: '请输入新密码', trigger: 'blur' }">
              <el-input class="pass-input" maxlength="20" type="password" v-model.trim="basicForm.password" placeholder="请输入密码8-20位，大小写字母、数字、符号至少三种"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="password_confirmation" :rules="{ required: true, message: '请确认新密码', trigger: 'blur' }">
              <el-input
                class="pass-input"
                maxlength="20"
                type="password"
                v-model.trim="basicForm.password_confirmation"
                placeholder="请再次输入密码8-20位，大小写字母、数字、符号至少三种"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="nickname">
              <p style="margin-bottom: 15px; color: #71777d">{{ phone }}</p>
              <!--            <el-button v-if="!isMobile" @click="getCode()">获取验证码</el-button>-->
              <el-input class="yz-input" v-model="basicForm.verification_code" maxlength="6" placeholder="请输入验证码">
                <el-button v-if="!isMobile" @click="getCode()" slot="append" style="color: #81d8d0; background: #fff; padding: 9px 20px">获取验证码</el-button>
                <el-button slot="append" v-else icon="">{{ time }}秒后获取</el-button>
              </el-input>
              <p v-if="isMobile" style="font-size: 12px; font-weight: 400; color: #ffa509">验证码已发送，请注意查收</p>
            </el-form-item>
          </el-form>
          <div class="form-operation">
            <el-button class="form-operation-item" @click="close" round>取消</el-button>
            <el-button class="form-operation-item" type="primary" round @click="confirm()">确认</el-button>
          </div>
        </el-tab-pane>
        <!--    基本信息结束   -->
      </el-tabs>
    </div>
    <div class="bottom-hint-box align-center">
      {{ $static.footer_text }}
      <a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Index',
  data() {
    return {
      isEdit: false,
      imageUrl: '',
      activeName: 'first',
      labelPosition: 'right',
      basicForm: {},
      phone: '',
      isMobile: false,
      key: '',
      time: 120,
      isShow: false,
    };
  },
  created() {
    this.getInfo();
    this.isShow = true;
  },
  methods: {
    close() {
      this.$cache.removeLoginInfo();
      this.$router.push('/login');
    },
    getInfo() {
      let url = this.$api.userInfo;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.phone = res.data.data.mobile;
          this.isShow = true;
        }
      });
    },
    getCode: _.debounce(function () {
      if (this.isEdit) {
        return false;
      }
      let url = this.$api.verification;
      let data = {
        mobile: this.phone,
        type: 'forgot',
      };
      this.isEdit = true;
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.isMobile = true;
            setTimeout(() => {
              this.isEdit = false;
            }, 1000);
            this.basicForm.verification_key = res.data.data.key;
            this.setIntervalFun();
            this.$message.success('验证码已发送，请注意查收');
          } else {
            this.isEdit = false;
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.isEdit = false;
          this.$message.error(error.response.data.message);
        });
    }, 1000),
    confirm: _.debounce(function () {
      this.$refs.basicForm.validate((valid) => {
        if (valid) {
          if (this.isMobile) {
            let url = this.$api.resetPassword;
            let data = this.basicForm;
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('修改成功');
                  //   var time = Date.parse(new Date());
                  //   console.log(time, '==========================');
                  //   localStorage.setItem('updataTime', time);
                  this.$router.push('/home');
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          } else {
            this.$message.info('请先获取验证码');
          }
        } else {
          return false;
        }
      });
    }, 1000),
    setIntervalFun() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          this.isMobile = false;
          this.time = 120;
          this.basicForm.verification_key = '';
          clearInterval(this.timer);
          return;
        }
        this.time--;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/profile/index.less';
.box {
  width: 100%;
  height: 100%;
  min-width: 1200px;
  overflow-x: auto;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  background-color: #f6f8f9;
  .auth-top-bg {
    width: 910px;
    height: 520px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .auth-bot-bg {
    width: 100%;
    height: 290px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }
}
.profile {
  width: 850px;
  height: 814px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.form-operation {
  position: fixed;
  bottom: 60px;
}
.bottom-hint-box {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  color: #93999f;
  line-height: 20px;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 1;
  a {
    margin-left: 6px;
    color: #93999f;
    text-decoration: none;
  }
}
</style>
